<template>
    <div class="pages-body login-page p-d-flex p-flex-column">
        <div class="topbar p-p-3 p-d-flex p-jc-between p-flex-row p-ai-center">
            <div class="topbar-left p-ml-3 p-d-flex" routerLink="/">
                <div class="logo">
                    <img src="/assets/layout/images/meraneta-logo-white.png" alt="" />
                </div>
            </div>
            <div class="topbar-right p-mr-3 p-d-flex">
                <Button type="button" label="DASHBOARD" @click="goDashboard" class="p-button-text p-button-plain topbar-button"></Button>
            </div>
        </div>

        <div class="p-as-center p-mt-auto p-mb-auto" style="width: 28rem">
            <div class="pages-panel card p-d-flex p-flex-column">
                <div class="pages-header p-px-3 p-py-1 p-mb-5">
                    <h2>Forgot Password</h2>
                </div>
                <!-- <div class="pages-detail p-mb-6 p-px-6">Enter your Mobile No and we'll send you a OTP</div> -->
                <div class="input-panel p-d-flex p-flex-column p-px-3">
                    <div class="p-inputgroup p-field p-mb-0">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-mobile"></i>
                        </span>
                        <span class="p-float-label">
                            <InputText
                                type="text"
                                maxlength="10"
                                @keypress="validateNumber"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                required="true"
                                v-model="username"
                                autofocus
                                :class="{ 'p-invalid': submitted }"
                            />
                            <label for="mobileno">Mobile No</label>
                        </span>
                    </div>
                    <div class="p-field p-text-right p-text-primary">
                        <small><a href="/#/" class="layout-topbar-blue">Re-send OTP?</a></small>
                    </div>
                    <div class="p-field p-text-left" v-if="submitted">
                        <small class="p-invalid p-error">Mobile No is required.</small>
                    </div>
                    <Button class="login-button p-mb-5 p-px-3" label="Send OTP" @click="sendOTP"></Button>
                    <div class="p-field p-text-center">
                        <a @click="login" style="cursor: pointer">Back to Login</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            username: '',
            submitted: false,
            page: false,
            isValid: true,
        };
    },
    methods: {
        goDashboard() {
            window.location = '/#/clients';
        },
        sendOTP() {
            this.submitted = true;
        },
        login() {
            window.location = '/#/';
            this.submitted = true;
        },
        validateNumber: (event) => {
            let keyCode = event.keyCode;
            if (keyCode < 48 || keyCode > 57) {
                event.preventDefault();
            }
        },
    },
};
</script>

<style scoped>
</style>
